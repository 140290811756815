import React, { Component } from 'react'
import { Container } from 'react-bootstrap';
import { html } from './Content';

export default class Toc extends Component {
  render() {
    return (
        <div className="content" dangerouslySetInnerHTML={{__html: html}}></div>
    )
  }
}
