import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from '../Components/Header'
import Full from '../Home/Full';
import Privacy from '../Views/Privacy';
import Toc from '../Views/Toc';
import Footer from '../Components/Footer'
import Login from '../Views/Login';
import Subs from '../Views/Subs';


export default class Routes extends Component {
  render() {
    return (
        <Router>
        <div>
          <Header/>
          <Switch>
          <Route path="/" exact component={Full} />
          <Route path="/login" exact component={Login} />
          <Route path="/subscription" exact component={Subs} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/toc" component={Toc} />
          </Switch>
          <Footer/>

        </div>
      </Router>
    )
  }
}
