import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import firebase from 'firebase'
import PropTypes from 'prop-types'
import { async } from 'q';

export default class Login extends Component {



    state = { email: '', password: ''}

    onSubmit = async(e) => {
        e.preventDefault();
        
        

        try {

            const auth = await firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)

            this.props.history.push('/subscription')

            
            
        } catch (error) {
            console.log(error.message)
        }
        
    }

    onChange = (e) => {
        this.setState({ [e.target.id]: e.target.value})
    }

    render() {
        return (
            <Row className='w-100 h-100 p-5 d-flex flex-column align-items-center justify-content-center'>
                    <Col md={5} className={'my-5 p-md-5 p-lg-6 p-0 curved form-box'}>
                        <div className='text-center mb-5 mb-lg-3 title text-dark'>
                        Sign in to your account
                        </div>

                        <form className={'p-0 p-lg-4'} onSubmit={(e) => this.onSubmit(e)}>

                            <div className='form-grp'>
                                <label className='d-block my-gray'>
                                    Email
                                </label>

                                <input onChange={(e) => this.onChange(e)} type="email" id="email" name="email" required="required" className="form-control w-100" aria-required="true" aria-invalid="false"></input>
                            </div>

                            <div  className="mt-4 form-grp d-relative">
                                <label  className="d-block my-gray">Password</label>
                                <input onChange={(e) => this.onChange(e)} type="password" id="password" required="required" className="form-control w-100"></input>

                                </div>

                                <div  className="d-flex justify-content-between align-items-center">
                                    <label role="checkbox" className="el-checkbox flex-fill my-gray">
                                        <span aria-checked="mixed" className="el-checkbox__input">
                                            <span className="el-checkbox__inner">

                                        </span>
                                        <input type="checkbox" aria-hidden="true" className="el-checkbox__original" value=""/>
                                            </span><span className="el-checkbox__label">Keep me signed in</span></label>
                                            <button  type="submit"  className="try-btn blue flex-fill curved">SIGN IN</button></div>

                        </form>

                    </Col>
            </Row>
        )
    }
}
