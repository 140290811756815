import React, {Component} from 'react';
import './App.css';
import Router from './Routes';
import { BrowserRouter } from "react-router-dom";
import firebase from 'firebase'
require('firebase/firestore')

const config = {
  apiKey: "AIzaSyCHGSiO0DG27w_rcrqaqZto4DYqYAOh5YY",
  authDomain: "diyarapp-46fda.firebaseapp.com",
  databaseURL: "https://diyarapp-46fda.firebaseio.com",
  projectId: "diyarapp-46fda",
  storageBucket: "diyarapp-46fda.appspot.com",
  messagingSenderId: "382341817285"
};

firebase.initializeApp(config);
const settings = {};

firebase.firestore().settings(settings)


export default  class App extends Component {

  
  componentDidMount(){
    
  }

  

render(){
  return (
    <BrowserRouter>
     <Router/>
    </BrowserRouter>
   
  )
}
}

