import React, { Component } from 'react'
import Card from '../Components/Card';
import firebase from 'firebase'
import { Row, Modal, Button } from 'react-bootstrap';
import moment from 'moment'
import axios from 'axios'
import dateFns from 'date-fns'


export default class Subs extends Component {

    state = { show: false, plan: '', month: 0, user: {}, price: 0, loading: false  }

    buy = (plan, month, price) => {
        this.setState({ show: true, plan, month, price })

        
    }

    onComplete = (type) => {
        
        this.setState({ loading: true, show: false})
    axios.post(`https://asia-northeast1-diyarapp-46fda.cloudfunctions.net/api/v1/createCharge`, {
      amount: this.state.price.toString(),
	    currency: "KWD",
	    first_name: this.state.user.firstname,
	    last_name: this.state.user.lastname,
	    email: this.state.user.email,
	    number: "50000000",
         plan:this.state.plan,
        type,
	    numberOfMonth:  dateFns.addMonths(new Date(this.state.user.expiryDate.seconds * 1000), this.state.month) ,
	    uid: this.state.user.uid
    }).then(res => {
      
      window.location.assign(res.data.data);
      
    }).catch(err => {
      
      this.setState({ loading: false})
    })
    }


    componentDidMount(){
        const  user = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
              // User is signed in.

              const email = user.email;
              const uid = user.uid;

              const db = firebase.firestore();

              const usersRef = db.collection("users");

              usersRef.doc(uid).get().then((doc) => {
            

                if (!doc.exists) {
                  
                } else {
                 
                  this.setState({ user: doc.data()})
                }

                  }).catch(err => {
                    console.log(err);
                    
                  })

            } else {
            
              this.props.history.push('/')
            }
          })
    }

    render() {
        if(this.state.loading){
            return (
                <Row className='w-100 h-100 p-5 d-flex flex-column align-items-center justify-content-center'  style={{ marginTop: 100 }}>
                    <span>Wait a second</span>
                </Row>
                
            )
        }
        return (
            <Row className='w-100 h-100 p-5 d-flex flex-column align-items-center justify-content-center'  style={{ marginTop: 100, minHeight: '100%' }}>

                <h1>Hi {this.state.user.firstname} {this.state.user.lastname}</h1>
                <span>{this.state.user.plan}</span>
                <span>{this.state.user.plan === 'Free'  ? 'You are on a Free Plan' : `Your plan will expire ${this.state.user.expiryDate ? moment(new Date(this.state.user.expiryDate.seconds * 1000)).fromNow() : ''}`}</span>
                
                <Card class='card' month={1} price={2} plan='Silver'  onClick={() => this.buy('Silver',1, 2)}/>
                <Card class='card2' month={6} price={10} plan='Gold' onClick={() => this.buy('Gold',6, 10)}/>
                <Card class='card3' month={12} price={20} plan='Platinum' onClick={() => this.buy('Platinum',12, 20)}/>

                

                <Modal show={this.state.show} onHide={() => this.setState({ show: false })}>
                    <Modal.Header closeButton>
                     <Modal.Title>Purchase {this.state.plan} plan</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                <p>Are you sure you wanna buy this?</p>
                </Modal.Body>

                <Modal.Footer>
                 <Button variant="secondary" onClick={() => this.setState({ show: false })} >Close</Button>
                <Button variant="primary" onClick={() => this.onComplete('src_card')}>Credit Card</Button>
                <Button variant="primary" onClick={() => this.onComplete('src_kw.knet')}>Knet</Button>
                </Modal.Footer>
                </Modal>
               

                
            

            </Row>
            
        )
    }
}
