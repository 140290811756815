import React, {Component} from 'react';

import Home from '../Views/Home'
import  { Element, } from "react-scroll";
import SectionTwo from '../Views/SectionTwo';
import SectionThree from '../Views/SectionThree';


export default  class Full extends Component {

  

  

render(){
  return (
    <>
      <Element name="section1">
      <section style={{ backgroundColor: '#fffff'}}>
        <Home/>
      </section>
      </Element>

      <Element name="section2">
      <section style={{ backgroundColor: '#F8F9FB'}}>
        <SectionTwo/>
      </section>
      </Element>

      <Element name="section3">
      <section style={{ backgroundColor: '#fffff'}}>
        <SectionThree/>
      </section>
      
      </Element>
    
    
    </>
  )
}
}

