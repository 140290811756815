import React, { Component } from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import {Link} from "react-router-dom";

export default class Footer extends Component {
  render() {
    return (
      <footer style={styles.conatiner}>
        <Row  className='d-flex justify-content-center align-items-center'  style={styles.copyright}>
          <Col md={10} className='d-flex justify-content-between'>
              <span style={styles.link}>©2019 - Diyar - All right reserved</span>
              <div>
              <Link style={styles.link} to="/toc" >
                Terms & Conditions
              </Link>
              <Link style={styles.link} to="/toc" >
              Privacy Policy
             </Link>
             </div>
          </Col>
        </Row>
      </footer>
    )
  }
}


const styles = {
    conatiner: {
        backgroundColor: '#1A3263',
        height: 100,
        width: '100%'
    },
    copyright:{
      height: 100
    },
    link: {
      fontSize: 15,
      color: 'white', 
      marginRight: 20
  },
}