import React, { Component } from 'react'
import { html2 } from './Content2';

export default class Privacy extends Component {
  render() {
    return (
        <div className="content" dangerouslySetInnerHTML={{__html: html2}}></div>
    )
  }
}
