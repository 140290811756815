import React, { Component } from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import pic from '../Assets/Images/3.png'
import pic2 from '../Assets/Images/2.png'

export default class SectionTwo extends Component {
  render() {
    return (
        <Container fluid style={Styles.container}>
            <Row className="justify-content-center"  style={{ marginTop: 100}}> 
            <Col md={3} >
                <img style={Styles.svg} src={pic}/>
                </Col>
                <Col md={3} >
                <img style={Styles.svg} src={pic2}/>
                </Col>
                <Col md={4} className="justify-content-center text-left">
                <h1 style={Styles.title}>Watch your favorite videos without restrictions.</h1>
                <span style={Styles.text}>
                    Enjoy unlimited content of all the things you love, 
                </span>
                <p style={Styles.text}>
                    From your favorite Influencers, In Kuwait, Suadi Arabia,
                    & much more.
                </p>
                
                </Col>
                


            </Row>
            <Row>
                
            </Row>
       
        
     
      </Container>
    )
  }
}


const Styles = {
    container: {
        width: '100%',
        backgroundColor: '#F8F9FB',
        
        
    },
    subTitle:{
        fontWeight: 'bold',
        marginTop: 100,
        textTransform: 'uppercase',
        color: '#66C7F7'
    },
    title:{
        fontWeight: 'bold',
        fontSize: 30,
        color: '#2F3340',
        marginTop: 100,
    },
    svg: {
        height: 600,
        width: 'auto',
        marginTop: 100,
        marginBottom: 100,
        boxShadow: '0 3px 30px 0 rgba(11, 19, 54, 0.16)',
        borderRadius: 28
    },
    text: {
        fontSize: 20,
        color: '#9399AD'
    }
}