import React, { Component } from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import Svg from '../Svg/1.svg'
import google from '../Assets/Images/google-play-badge.png'
import apple from '../Assets/Images/apple.png'


export default class Home extends Component {
  render() {
    return (
        <Container fluid>
            <Row noGutters className="justify-content-center"  style={{ marginTop: 100}}> 
                <Col md={4} className="justify-content-center text-left">
                <h4 style={Styles.subTitle}>Best Video Content App</h4>
                <h1 style={Styles.title}>Diyar App</h1>
                <span style={Styles.text}>
                    Enjoy unlimited content of all the things you love, 
                </span>
                <p style={Styles.text}>
                    From your favorite Influencers, In Kuwait, Suadi Arabia,
                    & much more.
                </p>
                <Row>
                    <Col md={4} sm={6}>
                    <img src={google} style={Styles.google}/>
                    </Col>
                    <Col md={4} sm={6}>
                    
                    <img src={apple} style={Styles.apple}/>
                    </Col>
                
                </Row>
                
                
                </Col>
                <Col md={6}>
                <img style={Styles.svg} src={Svg}/>
                </Col>


            </Row>
            <Row>
                
            </Row>
            
       
        
     
      </Container>
    )
  }
}


const Styles = {
    container: {
        margin: -10,
        
    },
    subTitle:{
        fontWeight: 'bold',
        fontSize: 15,
        textTransform: 'uppercase',
        color: '#3C72E4',
        marginTop: 100,
        
    },
    title:{
        fontWeight: 'bold',
        fontSize: 100,
        color: '#2F3340'

    },
    svg: {
        height: 600,
        width: '100%'
    },
    text: {
        fontSize: 20,
        color: '#9399AD'
    },
    google: {
        width: 'auto',
        height: 70,
    },
    apple: {
        width: 'auto',
        height: 70,
    }

}