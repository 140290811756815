import React, { Component } from 'react'
import { Nav, Navbar, Container, } from 'react-bootstrap'
import {Link} from "react-router-dom";
import  Scroll from 'react-scroll';
import logo from '../newlogo.jpg'



export default class Header extends Component {

    
    state = {
        open: true,
        currentScrollHeight: 0
      };
    
      
      componentDidMount() {
    
        window.onscroll =()=>{

            if(window.scrollY < 153){
            
          this.setState({open: true})
          console.log(window.scrollY)
        }else{
            this.setState({open: false})
        }
     }
    
      }


      


  render() {
    const componentClasses = ['example-component'];
    if (this.state.open) { 
        componentClasses.push('show');
 }
    return (
        <Container fluid className={componentClasses.join(' ')}>
        <Navbar  bg="light" expand="lg" fixed="top" className={componentClasses.join(' ')}>
        <Navbar.Brand href="/"><img src={logo}  style={Styles.img}/></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="justify-content-end">
        
        <Nav >
        <Nav.Item>
          <Nav.Link style={Styles.link}  eventKey="disabled" >
          <Link style={Styles.link} to="/"  >
            Home
        </Link>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link style={Styles.link}  eventKey="disabled" >
          <Scroll.Link to="section2" spy={true} smooth={true} duration={250} >
            Download
        </Scroll.Link>
          </Nav.Link>
        </Nav.Item>
        
        <Nav.Item>
          <Nav.Link style={Styles.link}  eventKey="disabled" >
          <Link style={Styles.link} to="/login"  >
            Subscription
        </Link>
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link style={Styles.link}  eventKey="disabled" >
          <Scroll.Link to="section3" spy={true} smooth={true} duration={250} >
            About Us
        </Scroll.Link>
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link style={Styles.link}  eventKey="disabled" >
          <Link style={Styles.link} to="/privacy" >
            Privacy Policy
        </Link>
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link style={Styles.link}  eventKey="disabled" >
          <Link style={Styles.link} to="/toc" >
            Terms & Conditions
        </Link>
          </Nav.Link>
        </Nav.Item>
        </Nav>
        
        </Navbar.Collapse>
</Navbar>
</Container>
      
    )
  }
}


const Styles = {
    container: {
        backgroundColor: '#1A3263'
    },
    link: {
        fontSize: 15,
        color: '#9399AD'
    },
    img: {
        width: 50,
        height: 50,
        tintColor: '#1A3263'
    }
}