import React, { Component } from 'react'
import { Card } from 'react-bootstrap'

export default class CardSub extends Component {
    render() {
        return (
            <div onClick={this.props.onClick} className={`col-md-3 ${this.props.class} shadow p-3 mb-5 bg-white my-5 p-md-5 p-lg-6 p-0 d-flex flex-column align-items-center justify-content-center`} >
                <span style={styles.text}>{this.props.month} Month</span>
                <span style={styles.texti}>{this.props.price} Kuwaiti Dinar (KD)</span>
                <div className='d-flex flex-column align-items-center justify-content-center' style={styles.inside}>{this.props.plan}</div>
            </div>
        )
    }
}

const styles = {
    text: {
        fontSize: 22,
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: -0.41,
        textAlign: "center",
        color: "#ffffff"
    },
    inside: {
        paddingLeft: 10,
        paddingRight: 10,
        height: 21,
         borderRadius: 11.5,
        backgroundColor: "#c3f6ff",
        fontSize: 11,
        fontWeight: "500",
        fontStyle: "normal",
        letterSpacing: 0.13,
        textAlign: "center",
        color: "#2462e8"
    },
    texti: {
        fontSize: 17,
  fontWeight: "600",
  fontStyle: "normal",
  letterSpacing: -0.32,
  textAlign: "center",
  color: "#37e0ff"
    }
}


