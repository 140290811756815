import React, { Component } from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import pic from '../Assets/Images/4.png'
import pic2 from '../Assets/Images/5.png'
import google from '../Assets/Images/google-play-badge.png'
import apple from '../Assets/Images/apple.png'

export default class SectionTwo extends Component {
  render() {
    return (
        <Container fluid style={Styles.container}>
            <Row className="justify-content-center"  style={{ marginTop: 100}}> 
            
                <Col md={4} className="justify-content-center">
                <h1 style={Styles.title}>Search your favorite videos and influencers.</h1>
                <span style={Styles.text}>
                    Enjoy unlimited content with the search feature and  
                </span>
                <p style={Styles.text}>
                save them for watching in the future.
                </p>
                <Row>
                    <Col md={4} sm={6}>
                    <img src={google} style={Styles.google}/>
                    </Col>
                    <Col md={4} sm={6}>
                    
                    <img src={apple} style={Styles.apple}/>
                    </Col>
                
                </Row>
                
                </Col>
                <Col className="justify-content-center" md={3} >
                <img style={Styles.svg} src={pic}/>
                </Col>
                <Col className="justify-content-center" md={3} >
                <img style={Styles.svg} src={pic2}/>
                </Col>
                


            </Row>
            
            <Row>
                
            </Row>
       
        
     
      </Container>
    )
  }
}


const Styles = {
    container: {
        width: '100%',
        
        
    },
    subTitle:{
        fontWeight: 'bold',
        marginTop: 100,
        textTransform: 'uppercase',
        color: '#66C7F7'
    },
    title:{
        fontWeight: 'bold',
        fontSize: 30,
        color: '#2F3340',
        marginTop: 100,
    },
    svg: {
        height: 600,
        width: 'auto%',
        boxShadow: '0 3px 30px 0 rgba(11, 19, 54, 0.16)',
        borderRadius: 28,
        marginTop: 100,
        marginBottom: 100,
    },
    text: {
        fontSize: 20,
        color: '#9399AD'
    },
    google: {
        width: 'auto',
        height: 70,
    },
    apple: {
        width: 'auto',
        height: 70,
    }

}